import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const EVENTS_CREATE_BASE = process.env.REACT_APP_EVENTS_CREATE_BASE || "";

export const bulkCertificateIssue = createAsyncThunk(
  "bulkCertificateIssue",
  async (payload: {
    eventId: string;
    registrationIds: string[];
    issue_all?: boolean;
  }) => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + EVENTS_CREATE_BASE}/api/v1/certificates/bulk_issue`,
      payload
    );
    return data;
  }
);

const bulkCertificateIssueSlice = createSlice({
  name: "bulkCertificateIssue",
  initialState: {
    loading: false,
    error: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(bulkCertificateIssue.pending, (state, action) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(bulkCertificateIssue.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
    });
    builder.addCase(bulkCertificateIssue.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  }
});

export default bulkCertificateIssueSlice.reducer;
