import { Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import layoutTwo from "../components/layout/layoutTwo";
import EcnBreadCrumbs from "../components/StyledComponents/EcnBreadCrumbs";
import EcnInput from "../components/StyledComponents/V2/EcnInput";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getEventDetails } from "../redux/slices/EventSlices/getEventDetailsSlice";
import { useNavigate, useParams } from "react-router-dom";
import { createCertificate } from "../redux/slices/EventSlices/createCertificateSlice";
import CertificatePreviewDrawer from "../components/CreateCertificate/CertificatePreviewDrawer";
import { LoadingButton } from "@mui/lab";
import { enqueueSnackbar } from "notistack";

const TITLE_LIMIT = 30;
const HEADING_LIMIT = 250;

const CreateCertificate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { eventId } = useParams<{ eventId: string }>();

  const { eventDetailsData } = useAppSelector((state) => state.getEventDetails);
  const { loading, responseData } = useAppSelector(
    (state) => state.createCertificate
  );

  const [saveClick, setSaveClick] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [heading, setHeading] = useState("");
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (responseData && saveClick) {
      enqueueSnackbar("Certificate created successfully", {
        variant: "success"
      });
      setSaveClick(false);
      navigate(`/event/${eventId}/?tab=additional-details`);
    }
  }, [responseData, saveClick]);

  useEffect(() => {
    if (!eventDetailsData) dispatch(getEventDetails({ eventId }));
    else {
      setTitle(eventDetailsData?.certificate_settings?.title || "");
      setHeading(eventDetailsData?.certificate_settings?.heading || "");
    }
  }, [eventDetailsData]);

  const saveCertificateHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const title = formData.get("title") as string | null;
    const heading = formData.get("heading") as null;

    if (title && heading && eventId) {
      dispatch(
        createCertificate({ event_id: eventId, settings: { title, heading } })
      );
      setSaveClick(true);
    }
  };

  return (
    <div className="create-certificate-page">
      <EcnBreadCrumbs>
        <p onClick={() => navigate(`/events?tab=events`)}>Events</p>
        <p onClick={() => navigate(`/event/${eventId}/?tab=event-details`)}>
          {eventDetailsData?.name?.slice(0, 25)}...
        </p>
        <p>Create Certificate</p>
      </EcnBreadCrumbs>
      <div className="body">
        <h1 className="page-title">Create Certificate</h1>
        <div className="sub-heading">
          Fields Like Name of the Candidate, Event Title, Signature, and QR code
          will be automatically added from our end !
        </div>

        <form ref={formRef} onSubmit={saveCertificateHandler}>
          <div className="field">
            <label htmlFor="certificate-title" className="field-label">
              Certificate Title*
            </label>
            <EcnInput
              className="field-input"
              value={title}
              onChange={(e) => {
                if (e.target.value.length < TITLE_LIMIT + 1)
                  setTitle(e.target.value);
              }}
              name="title"
              id="certificate-title"
              defaultValue={eventDetailsData?.certificate_settings?.title}
            />
            <span className="input-char-limit">
              {title.length} / {TITLE_LIMIT}
            </span>
          </div>
          <div className="field">
            <label htmlFor="event-title" className="field-label">
              Event Description*
            </label>
            <EcnInput
              className="field-input"
              rows={4}
              multiline
              value={heading}
              onChange={(e) => {
                if (e.target.value.length < HEADING_LIMIT + 1)
                  setHeading(e.target.value);
              }}
              name="heading"
              id="event-title"
              defaultValue={eventDetailsData?.certificate_settings?.heading}
            />
            <span className="text-area-char-limit">
              {heading.length} / {HEADING_LIMIT}
            </span>
          </div>
          <div className="buttons-wrapper">
            <Button variant="outlined" onClick={() => setDrawerOpen(true)}>
              Preview Certificate
            </Button>
            <LoadingButton
              loading={loading}
              loadingPosition="end"
              variant="contained"
              type="submit"
            >
              Save
            </LoadingButton>
          </div>
        </form>
      </div>
      <CertificatePreviewDrawer
        {...{ drawerOpen, setDrawerOpen, title, heading }}
        eventName={eventDetailsData?.name || ""}
        onSave={() => formRef.current?.requestSubmit()}
      />
    </div>
  );
};

const modules = {
  toolbar: [["bold", "italic", "underline"]]
};

const formats = ["bold", "italic", "underline"];

export default layoutTwo(CreateCertificate);
