import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const EVENTS_CREATE_BASE = process.env.REACT_APP_EVENTS_CREATE_BASE || "";

export const getEventAttendees = createAsyncThunk(
  "getEventAttendees",
  async (payload: {
    eventId: string;
    skip?: number;
    limit?: number;
    q?: string | null;
  }) => {
    const { data } = await axios.get(
      `${
        GATEWAY_BASE + EVENTS_CREATE_BASE
      }/api/v1/event/attendees/${payload.eventId}?skip=${payload.skip || 0}&limit=${payload.limit || 9999}${payload.q && "&q=" + payload.q}`
    );
    return data;
  }
);

const getEventAttendeesSlice = createSlice({
  name: "getEventAttendees",
  initialState: {
    loading: false,
    eventAttendeesData: null as any,
    error: false,
    success: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEventAttendees.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getEventAttendees.fulfilled, (state, action) => {
      state.loading = false;
      state.eventAttendeesData = action.payload;
      state.success = true;
    });
    builder.addCase(getEventAttendees.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    });
  }
});

export default getEventAttendeesSlice.reducer;
