import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const EVENTS_CREATE_BASE = process.env.REACT_APP_EVENTS_CREATE_BASE || "";

export const getEventAttendeesCount = createAsyncThunk(
  "getEventAttendeesCount",
  async (payload: { eventId: string }) => {
    const { data } = await axios.get(
      `${
        GATEWAY_BASE + EVENTS_CREATE_BASE
      }/api/v1/event/attendeesCount/${payload.eventId}`
    );
    return data;
  }
);

const getEventAttendeesCountSlice = createSlice({
  name: "getEventAttendeesCount",
  initialState: {
    loading: false,
    eventAttendeesCountData: null as any,
    error: false,
    success: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEventAttendeesCount.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getEventAttendeesCount.fulfilled, (state, action) => {
      state.loading = false;
      state.eventAttendeesCountData = action.payload;
      state.success = true;
    });
    builder.addCase(getEventAttendeesCount.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    });
  }
});

export default getEventAttendeesCountSlice.reducer;
