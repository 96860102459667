import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const EVENTS_CREATE_BASE = process.env.REACT_APP_EVENTS_CREATE_BASE || "";

interface SettingsProps {
  title: string;
  heading: string;
}
interface CreateCertificateProps {
  event_id: string;
  settings: SettingsProps;
}

export type PaymentStatusType = "Paid" | "Due";
export const createCertificate = createAsyncThunk(
  "createCertificate",
  async (certificateData: CreateCertificateProps) => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + EVENTS_CREATE_BASE}/api/v1/event/certificate-settings`,
      certificateData
    );
    return data;
  }
);

const createCertificateSlice = createSlice({
  name: "createCertificate",
  initialState: {
    loading: false,
    error: false,
    responseData: null as any
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createCertificate.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createCertificate.fulfilled, (state, action) => {
      state.loading = false;
      state.responseData = action.payload;
    });
    builder.addCase(createCertificate.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  }
});

export default createCertificateSlice.reducer;
